* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  line-height: 1.6;
}

:root {
  --primary-background-color: #fcfcfc;
  --error-color: #e26a6a;
}

a,
a:active,
a:visited,
a:hover,
a:link {
  font: inherit;
  text-decoration: none;
}
